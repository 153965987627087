import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Form, Input, Alert } from 'reactstrap';
import { newsletterSignup } from "../../services/team"

class Newsletter extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            open: false,
            error: false,
            email: '',
            name: '',
            city: '',
            state: ''
        };
    }

    toggle() {
        this.setState({            
            open: !this.state.open
        });        
    }

    async handleSignup() {
        this.setState({error: false});
        let data = await newsletterSignup(this.state.email, this.state.name, this.state.city, this.state.state)
        if (data !== 200) {
          this.setState({error: true})
        }
        else {
            this.setState({email: '', name: '', city: '', state: ''})
            this.toggle();
        }
    
    
      }

    render() {
            
        let cta = "Sign up for our newsletter to keep up what's going on in your area!";

        if (this.props.cta !== undefined) {
            cta = this.props.cta
        }


        return (
            <div>
                <Row style={{ backgroundColor: "white", padding: "10px", marginBottom: "25px", borderTop: "4px solid #343a40" }} noGutters>
                    <Col md={8} style={{margin: "auto 0"}}>{cta}</Col>
                    <Col md={4} className="text-right"><Button color="success" type="button" onClick={() => this.toggle()}>Subscribe</Button></Col>
                </Row>


                <Modal isOpen={this.state.open} toggle={() => this.toggle()}>
                    <ModalHeader toggle={() => this.toggle()}>Newsletter Sign Up</ModalHeader>
                    <ModalBody>
                        <div style={{ marginBottom: "25px" }}>Enter your email address, first name and your local city and state.  We will keep you up to date to changes at Fastpitchhub and anything new in your area.  We will never sell your information.</div>
                        <Form>
                            <FormGroup row>
                                <Label sm={3}>Email</Label>
                                <Col sm={9}>
                                    <Input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>First Name</Label>
                                <Col sm={9}>
                                    <Input type="text" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>City</Label>
                                <Col sm={9}>
                                    <Input type="text" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label  sm={3}>State</Label>
                                <Col sm={9}>
                                    <Input type="select" value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}>
                                        <option value="">Select Your State</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>



                                    </Input>
                                </Col>
                            </FormGroup>
                        </Form>

                        {this.state.error && <Alert color="danger">Un oh, looks like we had a problem signing you up.  Can you check your information and try again?</Alert>}
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.handleSignup()}>Subscribe</Button>{' '}
                    </ModalFooter>
                </Modal>
            </div>
                
        )
        
    }

}

export default Newsletter;