import React from 'react';
import { navigate } from 'gatsby';
import { Row, Col, Button, Navbar, NavbarBrand } from 'reactstrap';
import './articlecardsummary.css';


class ArticleCardSummary extends React.Component {
    render() {

        let { article, summary } = this.props;

        return (
            <div className="articleCardSummary">
                <Row>
                    <Col>
                        <Navbar color="dark" dark>
                            <NavbarBrand className="mr-auto" style={{ color: "white", whiteSpace: "normal" }}>{article.headline}</NavbarBrand>
                        </Navbar>
                    </Col>
                </Row>
                <Row>
                        <Col md={12}>
                            <div className="articleCategory" role="button" tabIndex={0} onKeyDown={() => navigate(article.categorySlug)} onClick={() => navigate(article.categorySlug)}>{article.category}</div>
                            <div className="articleDate">{article.date}
                            {article.author != '' && 
                            <span> by <b>{article.author}</b></span>}
                            </div>
                            <div style={{ padding: "20px 5%" }}>

                                <div className="articleImageSummary"><img src={article.image} alt="" /></div>
                                <div className="articleContentSummary" dangerouslySetInnerHTML={{ __html: article.articleHtml }} />
                                <div className="articleContentFade"></div>
                            </div>
                        </Col>                
                </Row>


                <Row>
                    <Col>
                        <div className="articleContentButton" role="button" tabIndex={0} onClick={() => {  navigate("articles/" + article.slug) } } onKeyDown={() => { navigate("articles/" + article.slug) } }><Button>Read Article</Button></div>
                    </Col>
                </Row>

            </div>
        )
    }

}

export default ArticleCardSummary;