import React from 'react';
import { navigate } from 'gatsby';
import { Row, Col, Button, Navbar, NavbarBrand } from 'reactstrap';
import './articlecard.css';


class ArticleCard extends React.Component {
    render() {

        let { article, summary } = this.props;
        console.log(article)
        return (
            <div className="articleCard">
                <Row>
                    <Col>
                        <Navbar color="dark" dark>
                            <NavbarBrand className="mr-auto" style={{ color: "white", whiteSpace: "normal" }}><h1>{article.headline}</h1></NavbarBrand>
                        </Navbar>
                    </Col>
                </Row>
                <Row>
                        <Col md={12}>                                                        
                            <div className="articleDate">{article.date} 
                            {article.author != '' && 
                            <span> by <b>{article.author}</b></span>}
                            
                            </div>                            
                            <div style={{ padding: "20px 5%" }}>

                                <div className="articleImage"><img src={article.image} alt="" /></div>
                                {article.type === "rss" && <div>This article redirects to another website.</div>}
                                <div className="articleContent" dangerouslySetInnerHTML={{ __html: article.articleHtml }} />
                            </div>
                        </Col>

                </Row>

            </div>
        )
    }

}

export default ArticleCard;