import React from "react"
import Layout from "../../components/layout"
import { graphql } from 'gatsby'
import ArticleCard from "../../components/articlecard/articlecard"
import Newsletter from "../../components/newsletter/newsletter"
import Helmet from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'
import ArticleCardSummary from "../../components/articlecardsummary/articlecardsummary"

class ArticlePage extends React.Component {


    render() {

        const data = this.props.data
        const articles = data.allArticle.nodes;

        let articleCards = articles.map((article, index) => {
            return <ArticleCardSummary article={article} key={index} />
        })

        return (
            <Layout>
                <Helmet
                    title="Fastpitch Softball Articles"
                    meta={[
                        { name: 'description', content: "Articles related to fastpitch softball and parenting softball players" },
                    ]}
                />
                <div className="wrapper">
                    <Container>

                        <Row>
                            <Col>
                                <Newsletter />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {articleCards}
                            </Col>
                        </Row>

                    </Container>
                </div>
            </Layout>
        )
    }


}

export default ArticlePage

export const query = graphql`
  {  
    allArticle(sort: {order: DESC, fields: date}, limit: 100) {        
      nodes {
        slug
        id
        date(formatString: "dddd, MMMM Do YYYY")
        author
        headline
        category
        articleHtml
        image        
      }
    }    
  }
`